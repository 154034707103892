<style lang="scss">
@import "../../../node_modules/ag-grid-community/dist/styles/ag-grid.css";
@import "../../../node_modules/ag-grid-community/dist/styles/ag-theme-alpine.css";
</style>

<template>
  <v-container fluid style="height: 100%">
    <v-row>
      <v-app-bar dense>
        <v-app-bar-title><v-icon>mdi-database-search</v-icon>請求データ一覧</v-app-bar-title>
        <v-spacer></v-spacer>
        <v-form ref="searchForm" v-model="validSeearchForm" lazy-validation>
          <search-conditions @search="onBtnSearch" max-height="100%">
            <v-checkbox label="検索上限無し" v-model="searchModel.fetchLimitOverFlg" dense></v-checkbox>
            <v-select
              v-model="searchModel.billingStatus"
              :items="billingStatusList"
              label="請求ステータス"
              class="mx-2"
              clearable
            ></v-select>
            <v-row dense>
              <v-col>
                <dp-date-picker
                  v-model="searchModel.billingDateFrom"
                  class="mx-2"
                  label="伝票日付From"
                  dense
                  type="date"
                  :rules="[rules.billingedFrom]"
                ></dp-date-picker>
              </v-col>
              <v-col>
                <dp-date-picker
                  v-model="searchModel.billingDateTo"
                  class="mx-2"
                  label="伝票日付To"
                  dense
                  type="date"
                  :rules="[rules.billingedTo]"
                ></dp-date-picker>
              </v-col>
            </v-row>
            <v-select
              :items="slipDateList"
              label="締め日"
              class="mx-2"
              dense
              clearable
              v-model="searchModel.slipDate"
            ></v-select>
            <v-text-field
              v-model="searchModel.corporateName"
              label="法人名"
              dense
              :rules="[rules.maxLength(150)]"
            ></v-text-field>
            <v-text-field
              v-model="searchModel.customerName"
              label="取引先名"
              dense
              :rules="[rules.maxLength(150)]"
            ></v-text-field>
          </search-conditions>
        </v-form>
        <tooltip-icon-button icon="mdi-refresh" @click="onBtnSearch">リフレッシュ</tooltip-icon-button>
        <v-divider vertical></v-divider>
        <tooltip-icon-button
          :disabled="!rowsSelected"
          icon="mdi-check"
          @click="onBtnCheckInvoice"
          v-if="allowedAction(constants.printActions)"
          >請求書発行</tooltip-icon-button
        >
        <v-divider vertical></v-divider>
        <tooltip-icon-button icon="mdi-download" @click="onBtnExport" v-if="allowedAction(constants.csvActions)"
          >CSVダウンロード</tooltip-icon-button
        >
        <tooltip-icon-button icon="mdi-filter-off" @click="clearFilters()">フィルター解除</tooltip-icon-button>
        <v-divider vertical></v-divider>
        <tooltip-icon-toggle-button icon="mdi-information-outline" v-model="detailSelected"
          >詳細表示</tooltip-icon-toggle-button
        >
      </v-app-bar>
    </v-row>

    <v-row style="height: 100%">
      <v-col
        :style="gridStyle"
        :cols="detailSelected && !infoMaximum ? 9 : 12"
        v-show="!detailSelected || !infoMaximum"
      >
        <v-row>
          <v-col cols="2"
            ><v-card elevation="1" class="mt-2"
              ><v-card-text class="font-weight-bold ml-4 mb-4"
                >売上金額：￥{{ this.subtotalTotal.toLocaleString() }}</v-card-text
              ></v-card
            ></v-col
          ><v-col cols="5"
            ><v-card elevation="1" class="mt-2"
              ><v-card-text class="font-weight-bold ml-4 mb-4"
                >[選択]売上金額 (税抜)：￥{{ this.selectSubtotalTotal.toLocaleString() }}
              </v-card-text></v-card
            ></v-col
          >
          <v-col cols="5"
            ><v-card elevation="1" class="mt-2"
              ><v-card-text class="font-weight-bold ml-4 mb-4"
                >仕入金額合計：￥{{ this.purchasePriceTotalTotal.toLocaleString() }}</v-card-text
              ></v-card
            ></v-col
          ><v-spacer></v-spacer
        ></v-row>
        <ag-grid-vue
          id="BillingList"
          class="ag-theme-alpine"
          style="height: 100%"
          :defaultColDef="defaultColDef"
          :columnDefs="columnDefs"
          :rowData="rowData"
          :alwaysShowHorizontalScroll="true"
          :pagination="true"
          :paginationPageSize="selectedPageSize"
          :suppressCsvExport="false"
          :suppressExcelExport="true"
          :defaultCsvExportParams="defaultCsvExportParams"
          :localeText="localeText"
          rowSelection="multiple"
          :enableCellTextSelection="true"
          :suppressColumnVirtualisation="true"
          :frameworkComponents="frameworkComponents"
          @grid-ready="onGridReady"
          @selection-changed="onSelectionChanged"
          @filter-changed="onFilterChanged"
          :columnTypes="columnTypes"
          :getRowNodeId="(data) => data.billingNumber"
        >
        </ag-grid-vue>
      </v-col>
      <v-col
        v-if="detailSelected"
        id="BillingInfos"
        style="flex-basis: auto; display: flex"
        :cols="infoMaximum ? 12 : 3"
      >
        <v-divider vertical></v-divider>
        <billing-infos
          v-on:infoMaximum-event="infoMaximum = !infoMaximum"
          :details="selectedDetails"
          :billingNumber="selectedBillingNumber"
        ></billing-infos>
      </v-col>
    </v-row>
    <error-grid-dialog
      ref="invoiceErrorGrid"
      width="80%"
      height="80%"
      title="承認"
      :columns="errorColmuns"
    ></error-grid-dialog>
  </v-container>
</template>

<script>
import moment from "moment";
import { AgGridVue } from "ag-grid-vue";
import { AG_GRID_LOCALE_JA } from "../../models/ag-grid/locales";
import {
  NumericColumn,
  PercentColumn,
  DateColumn,
  FullDateColumn,
  CheckmarkColumn,
  IncludeFilter,
} from "../../models/ag-grid/columnTypes";
import BillingInfos from "../../components/billing/BillingInfos.vue";
import SearchConditions from "../../components/common/SearchConditions.vue";
import TooltipIconButton from "../../components/common/TooltipIconButton.vue";
import TooltipIconToggleButton from "../../components/common/TooltipIconToggleButton.vue";
import BaseBillingStatuses from "../../consts/billingStatus/CafereoBillingStatuses";
import { getDisplayDetails, typeFormat } from "../../models/ag-grid/helpers";
import { statuses as ApiStatus } from "../../libs/api-client";
import ErrorGridDialog from "../../components/common/ErrorGridDialog.vue";
import { BooleanFilter, SelectionFilter } from "../../components/ag-grid/filters";
import SlipDateList from "../../consts/SlipDateList";
import Validation from "../../libs/validation";

export default {
  name: "BillingSearch",
  data() {
    return {
      // 権限グループ
      constants: {
        printActions: ["C050102"],
        csvActions: ["C050103"],
      },
      gridStyle: { height: "95%" },
      columnDefs: null,
      rowData: null,
      gridApi: null,
      columnApi: null,
      defaultColDef: null,
      defaultCsvExportParams: null,
      detailSelected: false,
      rowsSelected: null,
      autoSizeAll: null,
      imageList: [],
      infoMaximum: false,
      billingStatusList: BaseBillingStatuses.all(),
      slipDateList: SlipDateList.all(),
      selectedPageSize: null,
      domLayout: null,
      localeText: AG_GRID_LOCALE_JA,
      selectedRow: null,
      searchModel: {
        fetchLimitOverFlg: false,
        billingStatus: "",
        billingDateFrom: "",
        billingDateTo: "",
        slipDate: "",
        keepfilter: false,
      },
      subtotalTotal: 0,
      purchasePriceTotalTotal: 0,
      selectSubtotalTotal: 0,
      billingAmountTotal: 0,
      columnTypes: {
        dpNumericColumn: NumericColumn,
        dpPercentColumn: PercentColumn,
        dpDateColumn: DateColumn,
        dpFullDateColumn: FullDateColumn,
        dpCheckmarkColumn: CheckmarkColumn,
      },
      frameworkComponents: {
        dpBooleanFilter: BooleanFilter,
        dpSelectionFilter: SelectionFilter,
      },
      validSeearchForm: null,
      rules: {
        maxLength: Validation.maxLength,
        billingedFrom: (value) => this.billingedFromRules(value),
        billingedTo: (value) => this.billingedToRules(value),
      },
      errorColmuns: [
        { headerName: "請求コード", field: "billingNumber", pinned: "left" },
        { headerName: "エラー内容", field: "errorMessage" },
      ],
    };
  },
  components: {
    AgGridVue,
    BillingInfos,
    SearchConditions,
    TooltipIconButton,
    TooltipIconToggleButton,
    ErrorGridDialog,
  },
  mounted() {
    this.$store.commit("ui/loading", true);
    this.onSearchClick();
    this.handleResize();
    // 画面解像度による画面サイズ取得
    this.gridStyle.height = this.gridHeightSize - 96.5 + "px";
    window.addEventListener("resize", this.handleResize);
  },
  methods: {
    clearFilters() {
      this.gridApi.setFilterModel(null);
      this.columnApi.applyColumnState({
        defaultState: { sort: null },
      });
    },
    onGridReady(params) {
      this.gridApi = params.api;
      this.columnApi = params.columnApi;
    },
    onBtnExport() {
      var allColumnIds = [];
      this.columnApi.getAllColumns().forEach(function (column, idx) {
        if (idx > 0 && !column.colDef.hide) allColumnIds.push(column.colId);
      });
      this.defaultCsvExportParams.fileName = "請求データ.csv";
      this.gridApi.exportDataAsCsv({
        columnKeys: allColumnIds,
        processCellCallback: (params) => typeFormat(params.column.colDef?.type, params.value),
      });
    },
    onSelectionChanged() {
      var selectedRows = [];
      selectedRows = this.gridApi.getSelectedRows();

      this.rowsSelected = true;
      selectedRows.forEach((row) => {
        if (!(row.invoiceApproval && row.billingStatus == BaseBillingStatuses.NEW)) {
          this.rowsSelected = false;
        }
      });
      if (selectedRows.length === 0) this.rowsSelected = false;

      this.defaultCsvExportParams.onlySelectedAllPages = selectedRows.length > 0;

      if (selectedRows.length === 1) {
        this.selectedRow = selectedRows[0];
      } else {
        this.selectedRow = null;
      }
      this.selectSubtotalTotal = 0;
      this.billingAmountTotal = 0;
      for (let row of selectedRows) {
        this.selectSubtotalTotal += row.purchaseTotalPrice;
        this.billingAmountTotal += row.billingAmount;
      }
    },
    async onBtnCheckInvoice() {
      let selectedRows = this.gridApi.getSelectedRows();
      let messageText = `選択されたデータの請求書を作成します`;
      const ok = await this.$dialog.confirm({ title: "請求書作成", text: messageText });
      if (ok) {
        try {
          this.loadingOn();
          var requestContents = [];
          for (let row of selectedRows) {
            requestContents.push({
              billingNumber: row.billingNumber,
              updateDatetime: row.updateDatetime,
            });
          }
          const result = await this.$store.dispatch("billing/invoice", { billings: requestContents });

          let contents = result.data?.contents?.billings;
          let error = result.data?.header;

          const errorRows = [];
          switch (error.resultCode) {
            case ApiStatus.consts.SUCCESS:
              for (let row of selectedRows) {
                let billing = contents.find((r) => r.billingNumber === row.billingNumber);
                row.billingStatus = billing.billingStatus;
                row.invoiceNumber = billing.invoiceNumber;
                row.updateDatetime = billing.updateDatetime;
                row.updateUser = billing.updateUser;
              }
              this.$dialog.notify.info(`請求書情報を登録しました`, { timeout: 2300 });
              break;
            case ApiStatus.consts.BUSINESS_ERROR:
            case ApiStatus.consts.ALREADY_CHANGED:
              this.gridApi.setColumnDefs(this.columnDefs);

              // エラーメッセージ格納
              if (error.messages) {
                Object.keys(error.messages).forEach((key) => {
                  errorRows.push({
                    billingNumber: key,
                    errorMessage: error.messages[key].join("<br>"),
                  });
                });
              }

              this.$refs.invoiceErrorGrid.open({ records: errorRows });
              this.$dialog.notify.error(`請求書発行エラーがあります。確認してください。`, { timeout: 2300 });
              break;
            default:
              this.redirectError();
              break;
          }
          this.gridApi.applyTransaction({ update: selectedRows });
        } catch (error) {
          console.error("BillingSearch::onBtnCheckInvoice", error);
          this.apiRequestError(error);
        } finally {
          this.loadingOff();
        }
      }
    },
    async onSearchClick() {
      try {
        this.loadingOn();

        const response = await this.$store.dispatch("billing/search", this.searchModel);
        if (ApiStatus.isSystemError(response.data?.header)) {
          return this.redirectError();
        }
        var result = response.data.contents;
        if (result.over) {
          this.$dialog.warning({
            title: "請求データ一覧",
            text: `検索上限数を超えました。結果は${result.limit}件まで表示されます。`,
            actions: ["OK"],
          });
        }
        if (Object.keys(result.billings).length === 0) {
          this.$dialog.warning({
            title: "請求データ一覧",
            text: `検索結果は0件です。`,
            actions: ["OK"],
          });
        }
        this.gridApi.setRowData(result.billings);
        var allColumnIds = [];
        this.columnApi.getAllColumns().forEach(function (column) {
          allColumnIds.push(column.colId);
        });
        this.columnApi.autoSizeColumns(allColumnIds);
        this.subtotalTotal = 0;
        this.purchasePriceTotalTotal = 0;
        result.billings.forEach((billings) => {
          if (billings.purchaseTotalPrice) {
            this.subtotalTotal += billings.purchaseTotalPrice;
          }
          if (billings.purchasePriceTotal) {
            this.purchasePriceTotalTotal += billings.purchasePriceTotal;
          }
        });
      } catch (error) {
        console.error("BillingSearch::onSearchClick", error);
        this.apiRequestError(error);
      } finally {
        this.loadingOff();
      }
    },
    onPageSizeChanged() {
      this.gridApi.paginationSetPageSize(this.selectedPageSize);
    },
    onInfoMaximum() {
      this.gridApi.paginationSetPageSize(this.selectedPageSize);
    },
    onFilterChanged() {
      this.subtotalTotal = 0;
      this.purchasePriceTotalTotal = 0;
      this.gridApi.forEachNode((row) => {
        if (row.displayed) {
          if (row.data.purchaseTotalPrice) {
            this.subtotalTotal += row.data.purchaseTotalPrice;
          }
          if (row.data.purchasePriceTotal) {
            this.purchasePriceTotalTotal += row.data.purchasePriceTotal;
          }
        }
      });
    },
    onBtnSearch() {
      const isValid = this.$refs.searchForm.validate();
      if (!isValid) {
        this.$dialog.notify.error(`入力エラーがあります`, { timeout: 2300 });
        return;
      }
      this.$refs.searchForm.resetValidation();
      this.onSearchClick();
    },
    billingedFromRules(value) {
      if (value == null || this.searchModel.billingDateTo == null) return true;
      if (moment(value).isAfter(this.searchModel.billingDateTo)) return "請求日Toより前の日付を指定してください";
      return true;
    },
    billingedToRules(value) {
      if (value == null || this.searchModel.billingDateFrom == null) return true;
      if (moment(value).isBefore(this.searchModel.billingDateFrom)) return "請求日Fromより後の日付を指定してください";
      return true;
    },
    onBtnExportError() {
      var allColumnIds = [];
      this.columnApi.getAllColumns().forEach(function (column) {
        allColumnIds.push(column.colId);
      });
      this.defaultCsvExportParams.fileName = "エラーメッセージ.csv";
      this.gridApi.exportDataAsCsv({ columnKeys: allColumnIds });
    },
  },
  beforeMount() {
    this.domLayout = "autoHeight";
    this.selectedPageSize = this.globalPageSize;
    this.defaultCsvExportParams = {
      allColumns: true,
      onlySelectedAllPages: false,
    };
    this.defaultColDef = {
      filter: "agTextColumnFilter",
      resizable: true,
      sortable: true,
      suppressSizeToFit: true,
      filterParams: {
        newRowsAction: "keep",
      },
    };
    this.columnDefs = [
      {
        headerName: "",
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true,
        checkboxSelection: true,
        filter: false,
        resizable: false,
        sortable: false,
        pinned: "left",
      },
      {
        headerName: "請求ステータス",
        field: "billingStatus",
        filter: "dpSelectionFilter",
        filterParams: { options: BaseBillingStatuses.all() },
        valueGetter: (params) => BaseBillingStatuses.of(params.data.billingStatus),
      },
      { headerName: "請求CD", field: "billingNumber" },
      { headerName: "社店CD", field: "companyStoreCode" },
      { headerName: "法人名", field: "companyName" },
      { headerName: "取引先名", field: "storeName", filterParams: IncludeFilter },
      { headerName: "請求書番号", field: "invoiceNumber" },
      {
        headerName: "伝票日付",
        field: "slipDate",
        type: "dpDateColumn",
      },
      { headerName: "伝票番号", field: "slipNo" },
      { headerName: "JANCODE", field: "janCode" },
      { headerName: "タイトル", field: "title", filterParams: IncludeFilter },
      { headerName: "商品名", field: "productName", filterParams: IncludeFilter },
      { headerName: "上代", field: "retailPrice", type: "dpNumericColumn" },
      { headerName: "卸掛率", field: "wholesaleRate", type: "dpPercentColumn" },
      { headerName: "卸単価", field: "wholesaleUnitPrice", type: "dpNumericColumn" },
      { headerName: "出荷数", field: "quantity", type: "dpNumericColumn" },
      { headerName: "単価合計金額", field: "purchaseTotalPrice", type: "dpNumericColumn" },
      { headerName: "消費税", field: "consumptionTax", type: "dpPercentColumn" },
      { headerName: "消費税対象額", field: "consumptionTaxTarget", type: "dpNumericColumn" },
      {
        headerName: "請求日",
        field: "billingDate",
        type: "dpDateColumn",
      },
      {
        headerName: "締め日",
        field: "deadline",
        type: "dpDateColumn",
      },
      { headerName: "伝票備考", field: "slipNote" },
      { headerName: "PO番号", field: "poNumber" },
    ];

    this.rowData = [];
  },
  computed: {
    selectedDetails() {
      if (!this.selectedRow) {
        return [];
      }
      return getDisplayDetails(this.selectedRow.billingNumber, this.gridApi, this.columnApi);
    },
    selectedBillingNumber() {
      if (!this.selectedRow) {
        return [];
      }
      return this.selectedRow.billingNumber;
    },
  },
  watch: {
    globalPageSize(size) {
      this.gridApi.paginationSetPageSize(size);
    },
    gridHeightSize(value) {
      this.gridStyle.height = value - 96.5 + "px";
    },
  },
};
</script>
