<template>
  <v-card>
    <v-card-title>
      <span class="headline"><v-icon>mdi-pencil-plus-outline</v-icon>仕入支払予定編集</span>
    </v-card-title>
    <v-divider></v-divider>
    <v-card-text>
      <v-form ref="paymentEntryForm">
        <v-card-subtitle>支払情報</v-card-subtitle>
        <v-container style="max-width: inherit">
          <v-row dense>
            <v-col>
              <v-textarea
                label="経理備考"
                :rules="[rules.maxLength(100)]"
                filled
                dense
                v-model="updateModel.accountingRemarks"
              ></v-textarea>
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <v-row dense>
            <v-col cols="12" sm="12">
              <v-spacer></v-spacer>
              <tooltip-icon-button :disabled="!selectedRows" icon="mdi-arrow-down-bold-outline" @click="onBtnSetUpdate"
                >支払情報反映</tooltip-icon-button
              >
            </v-col>
            <v-col cols="12" sm="12">
              <ag-grid-vue
                :domLayout="domLayout"
                class="ag-theme-alpine"
                :defaultColDef="defaultColDef"
                :columnDefs="columnDefs"
                :alwaysShowHorizontalScroll="true"
                :pagination="true"
                paginationPageSize="10"
                :localeText="localeText"
                rowSelection="multiple"
                @grid-ready="onGridReady"
                @selection-changed="onSelectionChanged"
                :columnTypes="columnTypes"
                :getRowNodeId="(data) => data.paymentCode"
                :frameworkComponents="frameworkComponents"
              >
              </ag-grid-vue>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions>
      <v-btn color="secondary" @click="onCancelClick">キャンセル</v-btn>
      <v-spacer></v-spacer>
      <v-btn color="primary" @click="onUpdateClick">更新</v-btn>
    </v-card-actions>
    <v-overlay :value="isLoading">
      <v-progress-circular indeterminate color="primary" size="64"></v-progress-circular>
    </v-overlay>
    <error-grid-dialog
      ref="updateErrorGrid"
      width="80%"
      height="80%"
      icon=""
      title="仕入支払予定編集"
      btnSubmit="登録"
      :columns="errorColmuns"
    ></error-grid-dialog>
  </v-card>
</template>

<script>
import { AgGridVue } from "ag-grid-vue";
import { AG_GRID_LOCALE_JA } from "../../models/ag-grid/locales";
import {
  NumericColumn,
  PercentColumn,
  DateColumn,
  CheckmarkColumn,
  MonthColumn,
} from "../../models/ag-grid/columnTypes";
import TooltipIconButton from "../common/TooltipIconButton.vue";
import ErrorGridDialog from "../../components/common/ErrorGridDialog.vue";
import { statuses as ApiStatus } from "../../libs/api-client";
import Validation from "../../libs/validation";

export default {
  name: "PaymentEntryForm",
  props: ["inputModel"],
  components: {
    AgGridVue,
    TooltipIconButton,
    ErrorGridDialog,
  },
  data() {
    return {
      updateModel: {},
      defaultModel: {
        accountingRemarks: null,
      },
      rules: {
        maxLength: Validation.maxLength,
      },
      defaultColDef: null,
      columnDefs: null,
      gridApi: null,
      columnApi: null,
      selectedRows: null,
      domLayout: null,
      localeText: AG_GRID_LOCALE_JA,
      updateList: [],
      columnTypes: {
        dpNumericColumn: NumericColumn,
        dpPercentColumn: PercentColumn,
        dpDateColumn: DateColumn,
        dpCheckmarkColumn: CheckmarkColumn,
        dpMonthColumn: MonthColumn,
      },
      frameworkComponents: {},
      errorColmuns: [
        { headerName: "支払CD", field: "paymentCode" },
        {
          headerName: "エラー内容",
          field: "errorMessage",
          wrapText: true,
          autoHeight: true,
          cellRenderer: function (param) {
            return param.data.errorMessage.join("<br>");
          },
        },
      ],
      errorRows: [],
      updateSuccessRecords: [],
    };
  },
  watch: {
    inputModel(inputModel) {
      this.init(inputModel);
    },
  },
  computed: {
    isLoading() {
      return this.$store.getters["ui/isLoading"];
    },
  },
  methods: {
    onGridReady(params) {
      this.gridApi = params.api;
      this.columnApi = params.columnApi;

      this.init(this.inputModel);

      var allColumnIds = [];
      this.columnApi.getAllColumns().forEach(function (column) {
        allColumnIds.push(column.colId);
      });
      this.columnApi.autoSizeColumns(allColumnIds);
    },
    onSelectionChanged() {
      this.selectedRows = this.gridApi.getSelectedRows();
    },
    onBtnSetUpdate() {
      const isValid = this.$refs.paymentEntryForm.validate();
      if (!isValid) {
        this.$dialog.error({ text: "入力エラーがあります", actions: ["OK"] });
      } else {
        this.selectedRows.forEach((row) => {
          row.accountingRemarks = this.updateModel.accountingRemarks;
        });
        this.gridApi.applyTransaction({ update: this.selectedRows });
        this.updateModel = this.defaultModel;
      }
    },
    reset() {
      this.init(this.inputModel);
    },
    init(inputModel) {
      this.updateModel = this.defaultModel;
      if (this.gridApi) this.gridApi.setRowData(JSON.parse(JSON.stringify(inputModel)));
      this.updateSuccessRecords = [];
    },
    onCancelClick() {
      this.$emit("cancel", this.updateSuccessRecords);
    },
    async onUpdateClick() {
      var requestRecords = [];
      this.errorRows = [];
      var isValid = true;
      this.gridApi.forEachNode((record) => {
        var error = this.validateRow(record.data);
        if (error != true) {
          this.errorRows.push({
            shippingInstructionId: record.data.shippingInstructionId,
            errorMessage: error,
          });
          isValid = false;
        } else {
          requestRecords.push(this.requestFormat(record.data));
        }
      });
      if (isValid) {
        try {
          this.loadingOn();
          const updateModel = { payments: requestRecords };
          const response = await this.$store.dispatch("payment/update", updateModel);
          let error = response.data?.header;
          // 一覧更新レコード
          let updateRows = [];
          this.errorRows = [];
          switch (error.resultCode) {
            case ApiStatus.consts.SUCCESS:
            case ApiStatus.consts.BUSINESS_ERROR:
            case ApiStatus.consts.ALREADY_CHANGED:
              // エラーメッセージ格納
              if (error.messages) {
                Object.keys(error.messages).forEach((key) => {
                  this.errorRows.push({
                    paymentCode: key,
                    errorMessage: error.messages[key],
                  });
                });
              }
              // 一覧部の更新分のレコード設定
              if (response.data.contents) {
                this.gridApi.forEachNode((row) => {
                  response.data.contents.payments.forEach((payment) => {
                    let isError = this.errorRows.some((r) => r.paymentCode === row.data.paymentCode);
                    if (payment.paymentCode == row.data.paymentCode && !isError) {
                      this.updateSuccessRecords.push(payment);
                      updateRows.push(payment);
                    }
                  });
                });
              }
              // エラー表示
              if (this.errorRows.length > 0) {
                // 確認ダイアログから更新した分を削除(エラーがある場合のみ)
                this.gridApi.applyTransaction({ remove: updateRows });
                this.$refs.updateErrorGrid.open({ records: this.errorRows });
                this.$dialog.notify.error(`支払データの更新処理に失敗したデータが存在します。ご確認ください。`, {
                  timeout: 2300,
                });
              } else {
                this.$dialog.notify.info(`支払データを更新しました<small>(${requestRecords.length} 件)<small>`, {
                  timeout: 2300,
                });
                this.$refs.updateErrorGrid.close();
                this.$emit("updated", this.updateSuccessRecords);
                this.reset();
              }
              break;
            default:
              this.redirectError();
              break;
          }
        } catch (error) {
          console.error("PaymentEntryForm::onUpdateClick", error);
          this.apiRequestError(error);
        } finally {
          this.loadingOff();
        }
      } else {
        this.$refs.updateErrorGrid.open({ records: this.errorRows });
        this.$dialog.notify.error(`更新データに入力エラーが存在します。ご確認ください。`, {
          timeout: 2300,
        });
      }
    },
    validateRow(row) {
      var ret = true;
      var messages = [];
      this.setValidMessage(this.rules.maxLength(100)(row.accountingRemarks), "経理備考", messages);
      if (messages.length > 0) ret = messages;
      return ret;
    },
    setValidMessage(check, culumnName, messages) {
      if (!(check === true)) messages.push(culumnName + "は" + check);
    },
    requestFormat(row) {
      return {
        paymentNumber: row.paymentCode,
        accountingRemarks: row.accountingRemarks,
        updateDatetime: row.updateDatetime,
      };
    },
  },
  beforeMount() {
    this.domLayout = "autoHeight";
    this.defaultColDef = {
      filter: "agTextColumnFilter",
      resizable: true,
      sortable: true,
      suppressSizeToFit: true,
      filterParams: {
        newRowsAction: "keep",
      },
    };
    this.columnDefs = [
      {
        headerName: "",
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true,
        checkboxSelection: true,
        filter: false,
        resizable: false,
        sortable: false,
        pinned: "left",
      },
      {
        headerName: "対象月",
        field: "targetMonth",
        type: "dpMonthColumn",
      },
      { headerName: "仕入先CD", field: "companyStoreCode" },
      { headerName: "仕入先略称", field: "supplierName" },
      { headerName: "入庫日", field: "receiptDate", type: "dpDateColumn" },
      { headerName: "入庫区分", field: "receiptType", filter: "agDateColumnFilter" },
      { headerName: "JANCODE", field: "janCode" },
      { headerName: "タイトル", field: "title" },
      { headerName: "商品名", field: "productName" },
      { headerName: "経理備考", field: "accountingRemarks" },
      { headerName: "入庫数", field: "receiptQuantity", type: "dpNumericColumn" },
      { headerName: "上代", field: "retailPrice", type: "dpNumericColumn" },
      { headerName: "仕入掛率", field: "wholesaleRate", type: "dpPercentColumn" },
      { headerName: "仕入単価", field: "purchasePrice", type: "dpNumericColumn" },
      { headerName: "仕入合計金額", field: "purchaseTotalPrice", type: "dpNumericColumn" },
      {
        headerName: "消費税",
        field: "consumptionTax",
        type: "dpPercentColumn",
      },
      {
        headerName: "請求額税込",
        field: "invoiceAmount",
        type: "dpNumericColumn",
      },
      {
        headerName: "消費税対象額",
        field: "consumptionTaxTarget",
        type: "dpNumericColumn",
      },
    ];
    this.init(this.inputModel);
  },
};
</script>
