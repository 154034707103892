<template>
  <v-card>
    <v-card-title>
      <span class="headline"><v-icon>mdi-pencil-outline</v-icon>請求書編集</span>
    </v-card-title>
    <v-divider></v-divider>
    <v-card-text>
      <v-form ref="invoiceForm" v-model="validEntryForm" lazy-validation>
        <v-card-subtitle>宛先情報</v-card-subtitle>
        <v-container style="max-width: inherit">
          <v-row dense>
            <v-col cols="12" sm="1">
              <v-text-field
                label="請求先〒"
                filled
                dense
                v-model="updateModel.invoiceZipCode"
                :rules="[rules.required, rules.maxLength(8), rules.zipCode]"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="10">
              <v-text-field
                label="請求先住所"
                filled
                dense
                v-model="updateModel.invoiceAddress"
                :rules="[rules.required, rules.maxLength(150)]"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="2">
              <v-text-field
                label="請求先TEL"
                filled
                dense
                v-model="updateModel.invoicePhoneNumber"
                :rules="[rules.required, rules.maxLength(13), rules.phoneNo]"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="3">
              <v-text-field
                label="請求担当者"
                filled
                dense
                v-model="updateModel.invoicePerson"
                :rules="[rules.required, rules.maxLength(30)]"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                label="請求書宛名"
                filled
                dense
                v-model="updateModel.invoiceMailingAddress"
                :rules="[rules.required, rules.maxLength(100)]"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-container>
        <v-divider></v-divider>
        <v-card-subtitle>請求情報</v-card-subtitle>
        <v-container style="max-width: inherit">
          <v-row dense>
            <v-col cols="12" sm="2">
              <dp-date-picker
                type="date"
                label="支払期日"
                filled
                dense
                v-model="updateModel.paymentDate"
              ></dp-date-picker>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" sm="8">
              <v-textarea
                label="請求備考"
                filled
                dense
                v-model="updateModel.invoiceRemark"
                :rules="[rules.maxLength(100)]"
                rows="2"
              ></v-textarea>
            </v-col>
          </v-row>
        </v-container>
        <v-divider></v-divider>
        <v-card-subtitle>履歴</v-card-subtitle>
        <v-container style="max-width: inherit">
          <v-row dense>
            <v-col cols="12" sm="3">
              <v-text-field label="更新日時" v-model="updateModel.updateDatetime" filled dense disabled></v-text-field>
            </v-col>
            <v-col cols="12" sm="3">
              <v-text-field label="更新者" v-model="updateModel.updateUser" filled dense disabled></v-text-field>
            </v-col>
            <v-col cols="12" sm="3">
              <v-text-field label="登録日時" v-model="updateModel.createDatetime" filled dense disabled></v-text-field>
            </v-col>
            <v-col cols="12" sm="3">
              <v-text-field label="登録者" v-model="updateModel.createUser" filled dense disabled></v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions>
      <v-btn color="secondary" @click="$emit('onDialogClose')">キャンセル</v-btn>
      <v-spacer></v-spacer>
      <v-btn color="primary" @click="onUpdateClick">更新</v-btn>
    </v-card-actions>
    <v-overlay :value="isLoading">
      <v-progress-circular indeterminate color="primary" size="64"></v-progress-circular>
    </v-overlay>
  </v-card>
</template>

<script>
import Validation from "../../libs/validation";
import { statuses as ApiStatus } from "../../libs/api-client";

export default {
  name: "InvoiceEntryForm",
  props: ["inputModel"],
  data() {
    return {
      updateModel: {},
      rules: {
        required: Validation.required,
        maxLength: Validation.maxLength,
        phoneNo: Validation.phoneNo,
        zipCode: Validation.isZipcode,
      },
      validEntryForm: null,
    };
  },
  watch: {
    inputModel(inputModel) {
      this.initUpdateModel(inputModel);
    },
  },
  computed: {
    isLoading() {
      return this.$store.getters["ui/isLoading"];
    },
  },
  methods: {
    initUpdateModel(inputModel) {
      // 引数を設定
      this.updateModel = JSON.parse(JSON.stringify(inputModel));
      if (this.$refs.invoiceForm) {
        this.$refs.invoiceForm.resetValidation();
      }
    },
    async onUpdateClick() {
      if (this.validate()) {
        try {
          this.loadingOn();
          let requestContents = {
            invoice: {
              invoiceNumber: this.updateModel.invoiceNumber,
              invoiceZipCode: this.updateModel.invoiceZipCode,
              invoiceAddress: this.updateModel.invoiceAddress,
              invoicePhoneNumber: this.updateModel.invoicePhoneNumber,
              invoicePerson: this.updateModel.invoicePerson,
              invoiceMailingAddress: this.updateModel.invoiceMailingAddress,
              invoiceRemark: this.updateModel.invoiceRemark,
              paymentDate: this.updateModel.paymentDate,
              updateDatetime: this.updateModel.updateDatetime,
            },
          };
          const result = await this.$store.dispatch("invoice/update", requestContents);
          switch (result.data?.header.resultCode) {
            case ApiStatus.consts.SUCCESS:
              this.$emit("onEditSubmit", result.data.contents.invoice);
              this.initUpdateModel(result.data.contents.invoice);
              this.$dialog.notify.info(`請求書情報を更新しました(${result.data.contents.invoice.invoiceNumber})`, {
                timeout: 2300,
              });
              break;
            case ApiStatus.consts.ALREADY_CHANGED:
              this.$dialog.warning({
                title: `請求書編集`,
                text: result.data.header.messages[this.updateModel.invoiceNumber].join(),
                actions: ["OK"],
              });
              break;
            default:
              this.redirectError();
              break;
          }
        } catch (error) {
          console.error("CorporationUpdate::onUpdateClick", error);
          this.apiRequestError(error);
        } finally {
          this.loadingOff();
        }
      }
    },
    validate() {
      const isValid = this.$refs.invoiceForm.validate();
      if (!isValid) {
        this.$dialog.error({ text: "入力エラーがあります", actions: ["OK"] });
      }
      return isValid;
    },
  },
  beforeMount() {
    this.initUpdateModel(this.inputModel);
  },
};
</script>
